import React from "react";

// Files Import
import Slider from "react-slick";
import { content } from "../../../data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StyledH3Heading from "../../../common/components/StyledH3Heading/StyledH3Heading";
import StyledH5Heading from "../../../common/components/StyledH5Heading/StyledH5Heading";
import StyledH6Heading from "../../../common/components/StyledH6Heading/StyledH6Heading";

const styles = {
  backgroundImage: "url('/assets/hero-section/cards-bg.png')",
};

const CardsSection = () => {
  const cardsSection = content.cardsSection;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div
      className="mx-auto flex flex-col items-center justify-center bg-no-repeat bg-cover bg-center max-w-[100%] py-16 gap-12"
      style={styles}
    >
      <div className="flex flex-col gap-1 text-center">
        <div>
          <StyledH5Heading fontColor={"text-white"} content={"Happy Tails"} />
          <div className="md:-mt-2">
            <StyledH3Heading
              fontColor={"text-white"}
              content={"Our Success Stories"}
            />{" "}
          </div>
        </div>
        <div>
          <p className="w-11/12 mx-auto text-sm text-white md:w-2/3 md:text-lg lg:text-xl">
            Happy pets, happy people – see what they’re saying about Paw Play Love! 🐾✨
          </p>
        </div>
      </div>
      {/* CARDS */}
      <div className="flex justify-center mx-auto w-11/12 sm:w-[80%]">
        <div className="flex flex-col w-[90%]">
          <Slider {...settings}>
            {cardsSection.map((item, index) => (
              <div key={index} className="flex flex-col px-2 md:flex-row">
                <div className="bg-white rounded-3xl flex flex-col items-center justify-start gap-2 text-center p-8 min-h-[400px]">
                  <div className="flex items-center justify-center">
                    <img src={item.imgUrl} alt="User Profile" />
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <StyledH6Heading
                      fontColor={"text-black"}
                      content={item.name}
                    />
                    <p className="text-[#515151] font-bold">{item.prof}</p>
                    <img
                      src={item.starImg}
                      alt="Rating Stars"
                      className="mt-1"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <p className="pt-2 text-xs text-grey sm:text-sm">
                      {item.content}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
