import React from "react";
import { content } from "../../data";
import { Link } from "react-router-dom";

const AppStore = () => {
  const appStore = content.appStore;
  return (
    <div className="flex gap-2">
      {appStore.map((item, index) => (
        <div key={index}>
          <Link to={item.link} target="_blank">
            <img src={item.imgUrl} alt="App Store" />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default AppStore;
